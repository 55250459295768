

.cart__item__card {
    width: 100%;
    min-height: 170px;
    height: auto;
    margin: 0 auto;
    background-color: hsla(0, 0%, 100%, 0);
    border-radius: 0;
    padding: 16px 0;
    position: relative;
    border-bottom: 1px solid #dfdfdf;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

}
.shop__cart__items .cart__item__card {
    margin: 15px auto;
}
.product-info .cart__item__card {
    margin: 0px auto;
}
.cart__item__image {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 0;
}

.item__image {
max-width: 100%;
    height: 128px;
    transition: 1s;
    background-color: none;
}
.cart-container.MuiBox-root {
    width: 36.15% !important;
}
.cart__item__detail {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: flex-start;
    margin-left: 0;
    min-width: 100px;
    width: 100px;
    margin:0 24px 0 0;
}
.card_to {
    width: 75%;
    height: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    flex-wrap: nowrap;
    margin: 0px 0;
	gap: 0px;
}
span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
    
    font-family: NexaWebRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.cart__item__name, .cart__item__price {
    font-style: normal;
    font-weight: 400;
    font-family: NexaWebRegular;
    font-size: 18px;
    line-height: 140%;
    text-transform: lowercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    text-align: left;
    margin: 0 12px 0 0;
}
.cart__item__name {
    width: auto;
    height: auto;
    font-family: NexaWebRegular;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cart__item__price {
    width: auto;
    height: 30px;
    font-family: NexaWebRegular;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cart__item__quantity button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-1yxmbwk {
    right: 0;
    top: 0;
}
.cart__item__quantity {
    cursor: pointer;
    width: 100%;
    height: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
}
.name-quantity, .name-size, .name-composition {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #BBBBBB;
    flex: none;
    order: 2;
    flex-grow: 0;
	padding: 0 16px 0 0;
}
.cart__item__info {
    margin: -8px 0;
    width: 100%;
    padding: 0;
}
.quantity__input {
    width: 30px;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__item__action {
    height: 40px;
    width: 20%;
    min-width: 120px;
    display: flex;
    justify-content: center;
}

.buy__item__button {
    cursor: pointer;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    align-self: center;
    
}

.buy__now__button__cart {
    width: 65%;
    height: 40px;
    border-radius: 25px;
    font-size: 0.8rem;
    background-color: #000000;
    font-weight:bold;
    border-style: none;
    color: #ffffff;
}
.remove__item__icon {
	cursor: pointer;
    height: 24px;
    position: absolute;
    right: 14px;
    top: 12px;
    width: auto;
}
.total__amount__label {
    width: 100%;
    margin: 0 auto;
    font-weight: 400;
    font-size: 12px;
    font-family: NexaWebRegular;
    text-transform: lowercase;
}
.total__amount__value {
    width: 20%;
    height: 25px !important;
    min-width: 100px;
    font-style: normal;
    font-size: 18px;
    line-height: 140%;
    text-align: right;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
    flex-grow: 1;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root {
    border: 0;
    color: #bbb;
    font-size: 12px;
    text-transform: lowercase;
    padding: 0;
    margin: 0;
    width: 18px;
    min-width: 18px;
}
button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedError.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButtonBase-root:hover {
    background: none;
    color: #10059f;
}

::-webkit-scrollbar {
     width: 0;
    height: 0; 
}

::-webkit-scrollbar-track {
    background: #dfdfdf; 
    border-radius: 0px;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@media (max-width:1024px) {
.cart-container.MuiBox-root {
    width: 100% !important;
}
.filter-section {
    align-items: flex-start;
    display: block !important;
}
}
/* CartCard.css */

/* Hide the SVG icon by default */
.remove__item__svg {
  display: none;
}

/* Display the text by default */
.remove__item__text {
  display: inline;
}

/* Media query for mobile devices */
@media (max-width: 1024px) {
.remove__item__icon {
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 0 !important;
    top: 6px !important;
    width: 24px !important;
}
	.card_to {
    width: 75%;
    height: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    text-align: left;
    flex-wrap: nowrap;
    margin: 0px 0;
	gap: 5px !important;
}
  /* Hide the text on mobile devices */
  .remove__item__text {
    display: none;
  }

  /* Display the SVG icon on mobile devices */
  .remove__item__svg {
    display: inline;
  }
}
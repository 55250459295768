@media (min-width: 290px) and (max-width: 1024px) {
    .search-popup {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        background-color: rgb(32 32 32 / 75%) !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        z-index: 1000 !important;
        padding: 0 !important;
        overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
    }

    .popup-content.active {
        padding: 0 16px !important;
        top: 75px !important;
        max-height: 80vh; /* Ограничьте высоту активного контента */
        overflow-y: auto; /* Добавьте это для вертикальной прокрутки */
    }

    .search-results {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .search-result-item {
        max-width: 49%; /* Делаем элементы более компактными для мобильных устройств */
        width: 100%;
        margin-bottom: 12px;
    }

    .product__image {
        width: 100%; /* Убедитесь, что изображения масштабируются */
    }
}
.nav__top__container .search-popup {
    top: 0;
    width: 100%;
    position: fixed;
}

.popup-content {
    background-color: #fff;
    padding: 88px 0 0;
    border-radius: 0;
    text-align: center;
    top: 0;
    position: absolute;
    width: 100%;
    opacity: 0;
    transform: translateY(-200%);
    transition: opacity .3s ease, transform .3s ease;
    overflow-y: auto;
}
.popup-content.active {
    opacity: 1;
    transform: translateY(0);
}

.popup-content {
    background-color: #fff;
    border-radius: 0;
    opacity: 0;
    overflow-y: auto;
    padding: 88px 0 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    transform: translateY(-200%);
    transition: opacity .3s ease, transform .3s ease;
    width: 100%;
}
.placeholder-text {
    position: relative;
    height: 22px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    text-align: left;
    margin: 16px 0;
}
.cart__item__card, .search-results {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 86px 0 0 0;
    grid-gap: 11px;
    grid-gap: 0;
    gap: 0;
    width: 100%;
}
.no-results {
    height: 22px;
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #000000;
    display: block;
    text-align: left;
    margin: 16px 0;
}
ul.search-results li.search-result-item .product-details h4.product-name {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0;
}
ul.search-results li.search-result-item .product-details p.product-price {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    text-transform: lowercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1B1B1B;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
ul.search-results li.search-result-item {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;
    max-height: 380px;
    max-width: 233px;
    padding: 0;
}
ul.search-results li.search-result-item .product-image {
    height: 254px;
    object-fit: cover;
    width: 196px;
}
ul.search-results li.search-result-item .product-details {
    text-align: left;
    display: block;
    padding: 8px 0px;
    width: 100%;
    height: 54px;
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
}
.close-popup {
    position: absolute;
    top: 24px;
    right: 102px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}
ul.search-results li.search-result-item .product-details .product__card__action button {
    border: 0;
    background: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    grid-gap: 10px;
    gap: 10px;
    margin: 12px 0 auto;
    order: 1;
    width: 48px;
    height: 16px;
    font-size: 12px;
    line-height: 135%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #bbb;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.popup-content.active .search-input {
    width: 100%;
    padding: 16px !important;
    font-size: 18px !important;
    border: 1px solid #ccc;
    max-width: 1262px;
    margin-top: 88px;
    margin: 0 auto;
    margin-top: 88px;
    display: flex;
    font-family: 'NexaWebRegular';
    position: relative;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #bbb;
}

button.search-button {
    border: 0;
    background: none;
}

.nav__top__container .search-popup {
    top: 0px;
}
.nav__top__container .search-popup .popup-content.active button.close-popup {
    top: 16px;
    right: 16px;
}
.nav__top__container .search-popup .popup-content.active input[type=text] {
    font-family: 'NexaWebRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #1b1b1b;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 0 auto !important;
    display: block;
    border-bottom: 1px solid #DFDFDF;
}
.nav__top__container .search-popup .popup-content.active button.search-btn {
    border: 0;
    background: none;
    top: -41px !important;
    display: block;
    position: relative;
    right: 138px;
}
.input-container {
    position: relative;
    margin-bottom: 16px;
}

.input-container label {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 16px;
    color: #aaa;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
color: #bbb;
    font-size: 12px;
    left: 0px;
    top: 7px;
}

@media (max-width: 1920px) {
	ul.search-results li.search-result-item {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;
    max-height: 386px;
    max-width: 196px;
    padding: 0;
}
.nav__top__container .search-popup .popup-content.active button.search-btn {
    background: none;
    border: 0;
    display: block;
    position: relative;
    right: 0px;
    top: -41px !important;
}
ul.search-results li.search-result-item .product-details .product__card__action button {
    width: 24px;
    height: 24px;
}
	
ul.search-results li.search-result-item .product-details .product__card__action button svg {
    height: 24px;
    width: 24px;
}
.popup-content.active ul.search-results a.product__link {
        max-width: 100%;
        width: 100%;
    }
	ul.search-results li.search-result-item .product-details .product__card__action {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    float: right;
    font-family: NexaWebRegular;
    height: 0;
    justify-content: space-between;
    padding: 0 5px;
    position: relative;
    right: 6px;
    top:12px;
    width: 40px;
}
}

@media (max-width: 768px) {
	    ul.search-results li.search-result-item .product-details .product__card__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        float: right;
        font-family: NexaWebRegular;
        height: 0;
        justify-content: space-between;
        padding: 0 5px;
        position: relative;
        right: 0px;
        top: -309px;
        width: 40px;
    }
	    ul.search-results li.search-result-item .product-details .product__card__action button {
        height: 20px !important;
        width: 20px !important;
    }
}@media (max-width: 1024px) {
	    ul.search-results li.search-result-item .product-details .product__card__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        float: right;
        font-family: NexaWebRegular;
        height: 0;
        justify-content: space-between;
        padding: 0 5px;
        position: relative;
        right: 0px;
        top: -369px;
        width: 40px;
    }
	    ul.search-results li.search-result-item .product-details .product__card__action button {
        height: 20px !important;
        width: 20px !important;
    }
}

@media (max-width: 1536px) {    
a.cart-button svg, a.wishlist-button svg, button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium svg, button.search-button svg {
        height: 16px !important;
        width: 18px !important;
    }
}
@media (max-width: 1440px) {
	
    ul.search-results li.search-result-item {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0px;
        max-height: 322px;
		max-width: 100%;
        padding: 0;
    }
	ul.search-results li.search-result-item .product-details .product__card__action button {
    height: 20px !important;
    width: 20px !important;
}
ul.search-results li.search-result-item .product-details .product__card__action button svg {
    width: 18px !important;
    height: 18px !important;
}
    .search-popup.desc .popup-content.active {
        height: 800px !important;
        overflow: auto;
        padding: 88px 200px 24px;
    }
}

@media (max-width: 440px) {
.popup-content.active ul.search-results a.product__link {
        max-width: 48% !important;
        width: 100%;
    }
ul.search-results li.search-result-item .product-details .product__card__action {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        float: right;
        font-family: NexaWebRegular;
        height: 60px;
        justify-content: space-between;
        padding: 0 5px;
        position: relative;
        right: 0;
        top: -296px;
        width: 40px;
    }
	.popup-content.active ul.search-results {
    grid-gap: 11px;
    align-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    list-style: none;
    margin: 24px auto;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
    min-height: auto;
}
}